import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Component = () => {
  const { t } = useTranslation()

  return <Helmet>
    <title>{t('title') || "Innen"}</title>

    <meta name="description" content={t('description') || ''} />
    <meta name="language" content={t('language') || ''} />

    <meta property="og:title" content={t('title') || 'Innen'} />
    <meta property="og:description" content={t('description') || ''} />

    <meta name="twitter:title" content={t('title') || 'Innen'} />
    <meta name="twitter:description" content={t('description') || ''} />
  </Helmet>
}

export default Component
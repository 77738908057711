import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { WhatsappIcon } from '../Icons/Whatsapp'
import { constants } from '../../constants';
import {
  SearchBar,
  Submit,
  Input
} from './styles'

interface ComponentProps { }

const Component = ({ }: ComponentProps) => {
  const [text, setText] = useState('')
  const { t } = useTranslation();

  return (
    <SearchBar>
      <WhatsappIcon style={{ fontSize: 28, color: 'black', position: 'absolute' }} />
      <Input
        onChange={(event) => setText(event?.target?.value)}
        placeholder={t('home.whatsap_input.placeholder') || ''}
      />
      <Submit href={`https://wa.me/+${constants.whatsapp.number}/?text=${text}`} target="_blank">{t('home.whatsap_input.call_to_action')}</Submit>
    </SearchBar>
  )
}

export default Component



const NavyBlue = "#05445E"
const BlueGrotto = "#189AB4"
const BlueGreen = "#75E6DA"
const BabyBlue = "#D4F1F4"

export const colors = {
  primary: NavyBlue,
  secondary: BlueGrotto,
  tertiary: BlueGreen,
  quaternar: BabyBlue,
};

import { useState } from "react";
import { PopupModal } from "react-calendly";
import { Button } from './styles'
import { CalendarIcon } from "../../../Icons/Calendar";
import { useTranslation } from 'react-i18next';

const Component = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false)


  return (
    <div>
      <Button
        onClick={() => setIsOpen(true)}
      >
        <CalendarIcon /> {t('calendly.call_to_action')}
      </Button>
      <PopupModal
        url="https://calendly.com/innen/30min"
        // pageSettings={this.props.pageSettings}
        // utm={this.props.utm}
        // prefill={this.props.prefill}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root") as HTMLElement}
      />
    </div>
  )
}

export default Component
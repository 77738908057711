import styled, { keyframes } from 'styled-components'

const fade_in = keyframes`
0% {
  opacity: 0
}
100% {
    opacity: 1;
}`;


const polygon_animation = keyframes`
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    animation-timing-function: ease-in-out;
  }
  20% {
    clip- path: polygon(0 0, 100% 12%, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 0, 100% 12%, 100% 100%, 0 100%);
    animation-timing-function: ease;
	}
  100% {
    clip-path: polygon(0 100%, calc(100% - 20px) 80%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 calc(100% - 20px), 100% 80%, 100% 100%, 0% 100%);
    animation-timing-function: ease-in;
	}
`

const polygon_animation_tablet = keyframes`
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    animation-timing-function: ease-in-out;
  }
  20% {
    clip- path: polygon(0 0, 100% 12%, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 0, 100% 12%, 100% 100%, 0 100%);
    animation-timing-function: ease;
	}
  100% {
    clip-path: polygon(0 calc(100% - 90px), 100% calc(100% - 130px), 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 calc(100% - 90px), 100% calc(100% - 130px), 100% 100%, 0% 100%);
    animation-timing-function: ease-in;
	}
`

const polygon_animation_mobile = keyframes`
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    animation-timing-function: ease-in-out;
  }
  20% {
    clip- path: polygon(0 0, 100% 12%, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 0, 100% 12%, 100% 100%, 0 100%);
    animation-timing-function: ease;
	}
  100% {
    clip-path: polygon(0 calc(100% - 140px), 100% calc(100% - 190px), 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 calc(100% - 140px), 100% calc(100% - 190px), 100% 100%, 0% 100%);
    animation-timing-function: ease-in;
	}
`

export const Logo = styled('img')`
  opacity: 0;
  animation-name: ${fade_in};
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`

export const VideoFrame = styled('div')`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled('div')`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 100px;

  @media (min-width: 768px) {
    margin-bottom: 0px;
  }
`

export const Title = styled('h1')`
  color: white;
  font-size: ${({ theme }) => theme.fonts.sizes.h1};
  margin: 40px 0px;
  font-weight: 400;
  text-align: center;

  @media (min-width: 1024px) {
    max-width: 50%;
  }
`

export const Curtain = styled('div')`
  width: 100vw;
  height: 100vh;
  position:fixed; 
  top:0; 
  left:0; 
  z-index:999; 
  overflow:hidden; 
  display:flex; 
  align-items:center; 
  flex-direction:column; 
  justify-content:center;

  padding-left: 18px;
  padding-right: 18px;

  @media (min-width: 1024px) {
    padding-left: 70px;
    padding-right: 70px;
  }

  img {
    position:relative; 
    z-index:2; 
    mix-blend-mode:difference; 
    fill:white; 
    opacity:0; 
    position:relative; 
    transition:all 0.5s ease-in-out;
  }

  &.load {
    &:after{
      animation-name: ${polygon_animation_mobile};
      animation-duration: 1s; 
      animation-iteration-count: 1; 
      animation-fill-mode: forwards;
      
      @media(min-width: 768px){
        animation-name: ${polygon_animation_tablet};
      }   

      @media(min-width: 1024px){
        animation-name: ${polygon_animation};
      }      
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: #ffffff3b;
   
  }

  &:after{
    content: '';
    width: 100%; 
    height: 100%; 
    position: absolute; 
    top: 0; 
    left: 0; 
    z-index: 2; 
    background: black; 
  }
}
`


export const OpenMenuCta = styled('div')`
width:40px; 
height:15px; 
position:fixed; 
top:50px; 
left:70px; 
z-index:1000; 
opacity:0;
pointer-events:none;
cursor: pointer;

&.show{
  opacity:1;
  pointer-events:all;
  
  &:before,
  &:after{
    width:100%;
    height:1px;
    position:absolute;
    background:white;
    transition:all 0.35s ease-in-out;
    -webkit-transition:all 0.35s ease-in-out;
    transition-delay:0.5s;
    -webkit-transition-delay:0.5s;
    will-change:transform;
    content:'';
  }
}

	&.reverse:before,
	&.reverse:after{
    background:white;
  }

  &:before{
    top:0; 
    right:0; 
    transform-origin:right;
  }

  &:hover,
  &.open{
    &:before {
      transform:scaleX(0.5); 
      -webkit-transform:scaleX(0.5); 
      transition-delay:0s; 
      -webkit-transition-delay:0s;
    }
  }

  &.open:hover{
    &:before{
      transform:scaleX(1);
      -webkit-transform:scaleX(1);
    }
  }

	&:after{
    bottom:0;
    left:0;
    transform:scaleX(0.5);
    -webkit-transform:scaleX(0.5);
    transform-origin:left;
  }

	&:hover,
	&.open{
    &:after {
      transform:scaleX(1);
      -webkit-transform:scaleX(1);
      transition-delay:0s;
      -webkit-transition-delay:0s;
    }
  }

	&.open:hover{
    &:after{
      transform:scaleX(0.5);
      -webkit-transform:scaleX(0.5);
    }
  }

  &.open.black{
    &:before,
    &:after {
      background:black;
      transition-delay:0.2s;
      -webkit-transition-delay:0.2s;
    }
  }

  &.reverse.open.black,
  &.reverse.open.black{
    &:before,
    &:after {
      background:white;
    }
  }

  &.reverse,
  &.reverse {
    &:before,
    &:after {
      background:black;
    }
  }

	& > span{
    font-size:0;
  }

`

import { ArrowLargeRight } from '../../../Icons/ArrowLargeRight'
import Link from '../../../Link'
import { Center } from './styles'

interface ComponentProps {
}

const Component = ({ }: ComponentProps) => {
  return (
    <Center>
      <Link to="mailto:team@innen.io" target='_blank'>team@innen.io</Link>
      <ArrowLargeRight />
    </Center>
  )
}

export default Component
import styled from 'styled-components'

export const Header = styled('div')`
  position: fixed;
  left: 18px;
  right: 18px;
  top: 20px;
  z-index:1000; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
  cursor: pointer;
  opacity: 0;

  @media (min-width: 1024px) {
    left: 70px;
    right: 70px;
    top: 50px;
  }

  &.show{
    opacity:1;
    pointer-events:all;
  }
`
export const Body = styled('div')`
  height: 100vh;
  width: 100vw;
  padding-left: 18px;
  padding-right: 18px;

  @media (min-width: 1024px) {
    padding-left: 70px;
    padding-right: 70px;
  }
`
export const Footer = styled('div')`
  position: fixed;
  left: 18px;
  right: 18px;
  bottom: 20px;
  z-index:1000; 
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  pointer-events: none;
  opacity: 0;

  @media (min-width: 1024px) {
    left: 70px;
    right: 70px;
    bottom: 40px;
  }

  &.show{
    opacity:1;
    pointer-events:all;
  }

  `
export const Rights = styled('div')`
  margin-top: 10px;
  font-size: ${({ theme }) => theme.fonts.sizes.small};
  font-weight: 500;
  color: gray;
  text-align: right;
`

export const Center = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
import { useTranslation } from 'react-i18next';
import Typewriter from 'typewriter-effect';
import Video from '../../components/Video'
import SearchBar from '../../components/SearchBar'
import { Curtain, Logo, VideoFrame, Content, Title } from './styles'
import { useLayout } from '../../providers/LayoutProvider';
import backgroundVideo from '../../assets/videos/mobile.mp4'

const App = () => {
  const { layout, setLayout } = useLayout()
  const { t } = useTranslation();

  return (
    <>
      <VideoFrame>
        <Video onVideoLoaded={() => setLayout({ ...layout, loaded: true })} style={{ minWidth: '1940px' }}><source src={backgroundVideo} type="video/mp4" /></Video>
      </VideoFrame>

      <Curtain className={layout.loaded ? 'load' : ''}>
        <Content>
          <Logo src={layout.theme.logo.color} alt="Innen" width={270} />

          <Title>
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString(t('home.title.one'))
                  .callFunction(() => {

                  })
                  .pauseFor(2500)
                  .deleteAll()
                  .typeString(t('home.title.two'))
                  .callFunction(() => {

                  })
                  .start();
              }}
            />
          </Title>

          <SearchBar />
        </Content>
      </Curtain>

    </ >
  );
}

export default App;

import { useLayout } from '../../../../providers/LayoutProvider'
import Link from '../../../Link'

import {
  List,
  ListItem,
} from './styles'

const Component = () => {
  const { layout } = useLayout()

  if (!layout?.socialNetworks || layout?.socialNetworks.length <= 0) {
    return <></>
  }

  return <List>
    {layout?.socialNetworks.map((item) => (
      <ListItem key={`social-network-${item.name}`}>
        <Link to={item.link} target="_blank">
          <item.icon />
        </Link>
      </ListItem>
    ))}
  </List>
}

export default Component
import home from './home'
import calendly from './calendly'

const translations = {
  language: "English",
  title: "Innen - Empowering Businesses with Empathetic and Visionary Technology",
  description: "Discover Innen, where human-centered design meets cutting-edge technology. Explore our custom software solutions, optimized workflows, and AI-powered systems. We make business processes faster, smarter, and more efficient, helping you succeed in the digital world.",
  rights: 'All Rights Reserved',
  home,
  calendly
}

export default translations
import React, { useState, useRef, VideoHTMLAttributes, useMemo } from "react";
import { styled } from "styled-components";
import {
  Video,
} from "./styles";

export interface ComponentProps extends VideoHTMLAttributes<HTMLVideoElement> {
  children,
  fullScreen?: boolean
  backgroundVideo?: boolean
  onVideoLoaded?: () => void
}

export const Component = ({ children, fullScreen, backgroundVideo, onVideoLoaded, ...rest }: ComponentProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoSizes, setVideoSizes] = useState<{
    aspectRatio: number;
    width: number;
    height: number;
  }>();

  const videoRef = useRef<any>();

  const handleLoad = () => {
    const videoElement = videoRef?.current;

    if (videoElement) {
      handlePlay();

      if (videoElement.videoWidth && videoElement.videoHeight) {
        const aspectRatio =
          videoElement?.videoWidth / videoElement?.videoHeight;
        setVideoSizes({
          aspectRatio: aspectRatio,
          width: videoElement.videoWidth,
          height: videoElement?.videoHeight,
        });
      }

      setTimeout(() => {
        onVideoLoaded && onVideoLoaded()

      }, 2000)
    }
  };

  const handlePlay = () => {
    if (!isPlaying) {
      setIsPlaying(true);
      videoRef?.current?.play();
    } else {
      setIsPlaying(false);
      videoRef?.current?.pause();
    }
  };

  return (
    <Video
      ref={videoRef}
      onContextMenu={e => e.preventDefault()}
      onLoadedMetadata={handleLoad}
      controlsList="nodownload"
      preload="metadata"
      loop
      controls={false}
      playsInline
      muted
      poster="/path/to/poster-image.jpg"
      {...rest}
    >
      {
        React.Children.map(children, (child) => {
          if (!child) return <></>;

          return child.props.name
            ? React.createElement(child.type, {
              ...{
                ...child.props,
                onloadedmetadata: handleLoad
              },
            })
            : child;
        })
      }

      Tu navegador no soporta Video.
    </Video >
  );
};

export default Component
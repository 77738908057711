import { useEffect, useMemo } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import type { MenuProps } from 'antd';
import { Dropdown } from './styles';
import { useLayout } from '../../../../providers/LayoutProvider';
import { LanguageDto } from '../../../../types/language.dto';
import { getCurrentLanguage } from '../../../../helpers/get-current-language';


const Component = () => {
  const { layout } = useLayout()

  const currentLanguage: LanguageDto = useMemo(() => {
    return layout?.languages?.items?.filter(item => item?.code === layout?.languages?.default)[0]
  }, [layout.languages])

  const items: MenuProps['items'] = useMemo(() => {
    return layout?.languages?.items?.map((item) => ({
      key: item.code,
      label: <a href={`/${item.code}`} style={{ display: "flex", alignItems: 'center', justifyContent: 'center', gap: '8px' }}><img src={item.flag} alt={item?.code} width="14px" /> {item.name}</a>,
      ...item,
    }))
  }, [layout.languages])

  return (
    <Dropdown
      className={layout.loaded ? 'show' : ''}
      menu={{
        items,
        selectable: true,
        defaultSelectedKeys: [layout.languages.default],
      }}
    >
      <Typography.Link>
        <Space>
          <img src={currentLanguage?.flag} alt={currentLanguage?.code} width="18px" style={{ display: "block" }} /> {currentLanguage?.name}
          <DownOutlined />
        </Space>
      </Typography.Link>
    </Dropdown>
  )
};

export default Component;
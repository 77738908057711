const page = {
  whatsap_input: {
    placeholder: 'Pregúntanos cualquier cosa',
    call_to_action: 'Chat'
  },
  title: {
    one: 'De la ciencia social a la tecnología, transformamos tu negocio con empatía y perspectivas innovadoras.',
    two: 'Descubre lo Interno: Ilumina tus procesos, potencia tu negocio.'
  }
}

export default page
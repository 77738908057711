const page = {
  whatsap_input: {
    placeholder: 'Ask anything to us',
    call_to_action: 'Chat'
  },
  title: {
    one: 'Social scientists turned techies, redefining your business with empathetic out-of-the-box thinking.',
    two: 'Discover Inside: Illuminate your processes, empower your business.'
  }
}



export default page
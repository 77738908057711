import BlackTtf from "../../../../assets/fonts/inconsolata/Inconsolata-Black.ttf";
import BoldTtf from "../../../../assets/fonts/inconsolata/Inconsolata-Bold.ttf";
import ExtraBoldTtf from "../../../../assets/fonts/inconsolata/Inconsolata-ExtraBold.ttf";
import LightTtf from "../../../../assets/fonts/inconsolata/Inconsolata-Light.ttf";
import ExtraLightTtf from "../../../../assets/fonts/inconsolata/Inconsolata-ExtraLight.ttf";
import MediumTtf from "../../../../assets/fonts/inconsolata/Inconsolata-Medium.ttf";
import RegularTtf from "../../../../assets/fonts/inconsolata/Inconsolata-Regular.ttf";
import SemiBoldTtf from "../../../../assets/fonts/inconsolata/Inconsolata-SemiBold.ttf";

const fontName = "Inconsolata";
const fontSizeBase = 16;

const fontFace = `
  @font-face {
    font-display: swap;
    font-weight: 300;
    font-style: normal;
    font-family: ${fontName};
    src: local(${fontName}),
      url('${LightTtf}') format('truetype');
  }

  @font-face {
    font-display: swap;
    font-weight: 400;
    font-style: normal;
    font-family: ${fontName};
    src: local(${fontName}),
      url('${RegularTtf}') format('truetype');
  }

  @font-face {
    font-display: swap;
    font-weight: 500;
    font-style: normal;
    font-family: ${fontName};
    src: local(${fontName}),
      url('${MediumTtf}') format('truetype');
  }

  @font-face {
    font-display: swap;
    font-weight: 600;
    font-style: normal;
    font-family: ${fontName};
    src: local(${fontName}),
      url('${SemiBoldTtf}') format('truetype');
  }

  @font-face {
    font-display: swap;
    font-weight: 700;
    font-style: normal;
    font-family: ${fontName};
    src: local(${fontName}),
      url('${BoldTtf}') format('truetype');
  }
`;

export const fonts = {
  fontFace,
  family: `'${fontName}', monospace`,
  sizes: {
    base: `1rem`,
    small: '0.875rem',
    medium: `1.2rem`,
    large: `1.5rem`,
    caption: `${fontSizeBase - 2}px`,
    p: '1rem',
    h1: '1.5rem',
    h2: `${fontSizeBase + 18}px`,
    h3: `${fontSizeBase + 10}px`,
    h4: `${fontSizeBase + 2}px`,
    h5: `${fontSizeBase}px`,
    h6: `${fontSizeBase - 2}px`,
  },
};

import styled from 'styled-components'
import { Link as RrdLink } from 'react-router-dom'

export const Link = styled(RrdLink)`
  font-size: ${({ theme }) => theme.fonts.sizes.base};
  font-weight: 500;
  color: white;
  text-decoration: none;
  position: relative;

  &:hover {
    &:before {
      transform: scaleX(1);
    }
  }

  &:before {
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    margin: auto;
    transform: scaleX(0);
    transition: all 0.35s ease-in-out;
    will-change: transform;
    background-color: white;
    content: '';
  }
`
import home from './home'
import calendly from './calendly'

const translations = {
  language: "Español",
  title: "Innen - Potenciando Negocios con Tecnología Empática y Visionaria",
  description: "En Innen, combinamos las ciencias sociales y la tecnología para transformar tu negocio con perspectivas empáticas e innovadoras. Descubre cómo iluminar tus procesos y potenciar tu negocio con nosotros.",
  rights: 'Todos los derechos reservados',
  home,
  calendly
}

export default translations
import React, { useContext, useState } from "react";

import logoBlackImage from '../../assets/images/logo-innen-black.svg'
import logoWhiteImage from '../../assets/images/logo-innen-white.svg'
import logoFullColorImage from '../../assets/images/logo-innen-full-color.svg'

import { constants } from "../../constants";
import { LanguageDto } from "../../types/language.dto";
import { SocialNetworksDto } from "../../types/social-networks.dto";
import { getCurrentLanguage } from "../../helpers/get-current-language";

export interface Layout {
  loaded: boolean
  languages: {
    items: LanguageDto[]
    default: 'es' | 'en'
  }
  calendly: string
  socialNetworks: SocialNetworksDto[]
  theme: {
    name: string,
    logo: {
      white: string
      black: string
      color: string
    },
    colors: {
      primary: string,
      secondary: string,
      tertiary: string,
      quaternary: string,
    }
  }
}

const initialValues: Layout = {
  loaded: false,
  languages: {
    items: constants.languages.filter(item => item.enabled),
    default: getCurrentLanguage()
  },
  calendly: constants.calendly.link,
  socialNetworks: constants.socialNetworks.filter(item => item.enabled),
  theme: {
    name: 'main',
    logo: {
      white: logoWhiteImage,
      black: logoBlackImage,
      color: logoFullColorImage,
    },
    colors: {
      primary: '#05445E',
      secondary: '#189AB4',
      tertiary: '#75E6DA',
      quaternary: '#D4F1F4'
    }
  }
};

interface ContexInitialValuesDto {
  layout: Layout;
  setLayout: React.Dispatch<React.SetStateAction<Layout>>;
  resetLayout: () => void;
}

const contexInitialValues: ContexInitialValuesDto = {
  layout: initialValues,
  setLayout: () => { },
  resetLayout: () => { },
};

export const LayoutProvidersContext = React.createContext(contexInitialValues);

export const useLayout = () => useContext(LayoutProvidersContext);

const LayoutProvider = ({ children }) => {
  const [layout, setLayout] = useState<Layout>(initialValues);

  const resetLayout = () => {
    setLayout(initialValues);
  };

  return (
    <LayoutProvidersContext.Provider
      value={{
        layout,
        setLayout,
        resetLayout,
      }
      }>
      {children}
    </LayoutProvidersContext.Provider>
  );
};

export default LayoutProvider;

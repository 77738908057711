import styled from 'styled-components'

export const Center = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
  position: relative;
  margin-right: 32px;

  svg {
    position: absolute;
    left: calc(100% + 10px);
    top: 50%;
    transform: translate(0%, -50%);
    transition: left .5s ease-in-out;
  }

  a:hover + svg {
    left: calc(100% + 15px);
  }

`
import ScrollToTop from '../components/ScrollToTop'
import {
  useLocation,
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Layout from '../components/Layout'
import Home from '../pages/homepage'

const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<Home />} />,
    </Routes>
  )
}

const Component = () => {
  return (
    <BrowserRouter>
      <ScrollToTop useLocation={useLocation} />

      <Routes>
        <Route
          path='/'
          element={<Layout />}
        >
          <Route index element={<Home />} />,
        </Route>

        <Route
          path='/es/*'
          element={<Layout />}
        >
          <Route index element={<Home />} />,
        </Route>

        <Route
          path='/en/*'
          element={<Layout />}
        >
          <Route index element={<Home />} />,
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Component
import flagGB from "../assets/flags/flag-GB.png";
import flagES from "../assets/flags/flag-ES.png";
import { LanguageDto } from "../types/language.dto";

export const languages: LanguageDto[] = [
  {
    enabled: true,
    name: 'Español',
    flagIcon: "🇪🇸",
    code: "es",
    flag: flagES,
  },
  {
    enabled: true,
    name: 'English',
    flagIcon: "🇬🇧",
    code: "en",
    flag: flagGB,
  },
]
import { ThemeProvider } from 'styled-components'
import { main } from './skins/main'
import { GlobalStyles } from './GlobalStyles'
import { useLayout } from '../LayoutProvider'

const themes: any = {
  main
}

interface ComponentProps {
  children
}

const Component = ({ children }: ComponentProps) => {
  const { layout } = useLayout()

  return (
    <ThemeProvider theme={themes[layout.theme.name] || main}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

export default Component

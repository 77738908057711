import { calendly } from './calendly'
import { languages } from './languages'
import { socialNetworks } from './social-networks'
import { whatsapp } from './whatsapp'

export const constants = {
  calendly,
  languages,
  socialNetworks,
  whatsapp
}
import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${({ theme }) => theme.fonts.family};
    font-size: ${({ theme }) => theme.fonts.sizes.base};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.Black};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a,
  p,
  button,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  sup,
  sub,
  span,
  div,
   {
    font-family: ${({ theme }) => theme.fonts.family};
  }
`
import styled from 'styled-components'

export const SearchBar = styled('div')`
  background: rgba(255, 255, 255, 0.9);
  height: 46px;
  border-radius: 23px;
  backdrop-filter: blur(8px);
  width: 100%;
  padding: 0px 3px 0px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 450px;
`

export const Submit = styled('a')`
  font-size: ${({ theme }) => theme.fonts.sizes.base};
  background-color: black;//#05aca6;
  min-width: 80px;
  height: 40px;
  padding: 0px 20px;
  border-radius: 20px;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Input = styled('input')`
  all: unset;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0px 20px 0px 40px;
  color: black;
  font-size: 16px;
`
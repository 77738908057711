import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/config';
import './index.css';
import Metas from './components/Metas';
import Routes from './routes';
import ThemeProvider from './providers/ThemeProvider'
import reportWebVitals from './reportWebVitals';
import LayoutProvider from './providers/LayoutProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <LayoutProvider>
        <ThemeProvider>
          <Metas />
          <Routes />
        </ThemeProvider>
      </LayoutProvider>
    </I18nextProvider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import i18next from 'i18next';
import XHR from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import englishTranslation from './en';
import spanishTranslation from './es';
import { getCurrentLanguage } from '../helpers/get-current-language';

const supportedLngs = ["en", "es"]; // (*)

const resources = {
  en: {
    translation: englishTranslation,
  },
  es: {
    translation: spanishTranslation,
  },
};

i18next
  .use(XHR)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: { order: ["path", "navigator"] },
    fallbackLng: getCurrentLanguage(),
    supportedLngs,
    resources,
    debug: true,
  });

export default i18next

import { Link } from './styles'
interface ComponentProps {
  to: string, target: string, children: any
}

const Component = ({ children, ...rest }: ComponentProps) => {
  return (
    <Link {...rest}>{children}</Link>
  )
}

export default Component
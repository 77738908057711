import styled from 'styled-components'

export const Button = styled('button')`
font-size: ${({ theme }) => theme.fonts.sizes.base};
  background-color: transparent;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  cursor: pointer;
  padding: 0px;
`
import styled from 'styled-components'

export const List = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
`

export const ListItem = styled('li')`
a {
  font-size: 24px;
}
`
import { Facebook } from '../components/Icons/Facebook'
import { Github } from '../components/Icons/Github'
import { Instagram } from '../components/Icons/Instagram'
import { LinkedIn } from '../components/Icons/LinkedIn'
import { Tiktok } from '../components/Icons/Tiktok'
import { Twitter } from '../components/Icons/Twitter'
import { Vimeo } from '../components/Icons/Vimeo'
import { Youtube } from '../components/Icons/Youtube'
import { SocialNetworksDto } from '../types/social-networks.dto'

export const socialNetworks: SocialNetworksDto[] = [
  {
    enabled: false,
    link: '',
    name: 'facebook',
    icon: Facebook,
  },
  {
    enabled: false,
    link: '',
    name: 'github',
    icon: Github,
  }, {
    enabled: false,
    link: '',
    name: 'instagram',
    icon: Instagram,
  }, {
    enabled: true,
    link: 'https://www.linkedin.com/company/innen-lab/',
    name: 'linkedin',
    icon: LinkedIn,
  }, {
    enabled: false,
    link: '',
    name: 'tik-tok',
    icon: Tiktok,
  }, {
    enabled: false,
    link: '',
    name: 'twitter',
    icon: Twitter,
  }, {
    enabled: false,
    link: '',
    name: 'vimeo',
    icon: Vimeo,
  }, {
    enabled: false,
    link: '',
    name: 'youtube',
    icon: Youtube,
  }]